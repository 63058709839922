import PropTypes from "prop-types";
import React from "react";

import "./EcoGraph.scss";

const EcoGraph = ({ graphGraphic, graphTitle, graphSubtitle, width }) => {
  return (
    <div className="eco-graph">
      <div className="text-center">
        <img
          style={{ width: width }}
          src={graphGraphic}
          alt="CO2 emissions for EVs vs Gas vehicles"
          className="graph-image"
        />
      </div>
      <div className="graph-title">
        <h3 className="title-text">{graphTitle}</h3>
      </div>
      <div className="graph-subtitle">
        <div className="title-text">{graphSubtitle}</div>
      </div>
    </div>
  );
};

export default EcoGraph;

EcoGraph.propTypes = {
  graphGraphic: PropTypes.any,
  graphTitle: PropTypes.string,
  graphSubtitle: PropTypes.string,
};
