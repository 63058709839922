// @TODO abstract this out. One approach - upAndGo is contained within zip array, and that has the array of assets.
// Not sure if we'll be handling this issue in this manner though.
const upAndGoStations = {
    "98509": [
        "676 Woodland Square Loop SE"
    ],
    "98032" : [
        "24437 Russell Road"
    ]
};

const checkUpAndGoStation = (zip, street) => {
    if(upAndGoStations[zip]) {
        return upAndGoStations[zip].indexOf(street) > -1;  
      }
    
      return false;
}

export default checkUpAndGoStation;